app-sidebar {
  min-height: calc(100vh - 60px);
}

.sidebar {
  min-width: 320px;
  height: 100%;
  background-color: #f3f3f3;
  position: relative;
  z-index: 3;
  transition: all .35s;
}

@media (max-width: 1200px) {
  .sidebar {
    min-width: 80px;
  }
}