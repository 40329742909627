.popup {
  background-color: #fff;
  min-width: 320px;
  padding: 24px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 4px;
  z-index: 10;
}

.popup-close {
  position: absolute;
  top: 8px;
  right: 8px;
  transform: rotate(45deg);
  cursor: pointer;
}

.popup-close i {
  font-size: 20px;
}

.popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 9;
}

.popup-section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}