.app {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-wrap: wrap;
}

.app .main {
  padding: 16px;
  width: calc(100% - 320px);
}

@media (max-width: 1200px) {
  .app .main {
    width: calc(100% - 80px);
  }
}