.nav__list,
.nav__item,
.nav__link {
  display: block;
  width: 100%;
}

.nav__link {
  padding: 8px 16px;
  color: #333;
  transition: all .3s ease-in-out;
}

.nav__link:hover {
  color: #333;
  text-decoration: none;
  background-color: #e5e5e5;
}