app-header {
  width: 100%;
}

app-header .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  background-color: #dadada;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 16px;
  z-index: 5;
  justify-content: space-between;
}

app-header .header__right {
  display: flex;
  justify-content: flex-end;
}

app-header .header__right .avatar {
  height: 30px;
  width: 30px;
  background-color: #888;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}