table thead tr {
  background-color: #dadada;
}

table tbody input {
  margin: 0 auto !important;
  display: block;
}

table tbody tr td:nth-child(1) {
  border-left: 1px solid #dadada;
}

table tbody tr td:nth-last-child(1) {
  border-right: 1px solid #dadada;
}

table a,
table button {
  background-color: #dadada;
  border-radius: 4px;
  padding: 4px 6px;
  color: #333;
  border: none;
  min-width: 80px;
}

table a:hover,
table button:hover {
  color: #333;
  text-decoration: none;
  background-color: #f1f1f1;
}

table .center {
  text-align: center;
}

table .change-position.positive {
  color: green;
}

table .change-position.negative {
  color: red;
}

table .color {
  width: 100%;
  background-color: gray;
  height: 26px;
}

table .color-0.color {
  background-color: green;
}

table .color-1.color {
  background-color: red;
}

table .color-2.color {
  background-color: blue;
}

table .color-3.color {
  background-color: yellow;
}

table .color-4.color {
  background-color: black;
}

table .color-5.color {
  background-color: gray;
}

.dataTables_wrapper:after {
  background-color: #dadada !important;
  visibility: inherit;
  width: 100%;
  height: 44px;
}

table.dataTable,
table.dataTable.no-footer {
  margin: 0 !important;
}

table.dataTable,
table.dataTable.no-footer,
table.dataTable thead th {
  border-top: none !important;
  border-bottom: none !important;
}